import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLocation from './location.reducer';
import { selectRouteNestedParam } from './../../store/router/router.selectors';

export const selectLocationState = createFeatureSelector<fromLocation.State>(
  fromLocation.locationFeatureKey
);
import * as _ from 'lodash';
import { getCurrentProjectLocationUuid } from '../project/project.selectors';
import { Project } from '../project/project.reducer';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

const { selectIds, selectEntities, selectAll, selectTotal } =
  fromLocation.adapter.getSelectors();

const listSelectors = fromLocation.listAdapter.getSelectors(
  (state: fromLocation.State) => _.get(state, 'list', fromLocation.listAdapterInitialState)
);

/**
 * Get total amount of Locations loaded into the store
 */
export const getLocationsTotalInStore = createSelector(
  selectLocationState,
  listSelectors.selectTotal
);

/**
 * Get list config with current total in store
 */
export const getLocationListConfigWithCurrentTotal = createSelector(
  selectLocationState,
  getLocationsTotalInStore,
  (state: fromLocation.State, currentTotal) => ({
    ..._.get(state, ['list', 'listConfig']),
    page: 0,
    // we calculate a limit which is at least the number of entities in the store, uprounded to a full new page regarding to the limit
    limit:
      currentTotal !== 0
        ? Math.ceil(
            currentTotal / _.get(state, ['list', 'listConfig', 'limit'], 10)
          ) * _.get(state, ['list', 'listConfig', 'limit'], 10)
        : _.get(state, ['list', 'listConfig', 'limit']),
  })
);

/**
 * Get list config (sorting etc.)
 */
export const getLocationListConfig = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'listConfig'], {})
);

/**
 * Get Location list
 */
export const getLocations = createSelector(
  selectLocationState,
  listSelectors.selectAll
);

/**
 * Get Location list meta data (total etc.)
 */
export const getLocationsMeta = createSelector(
  selectLocationState,
  (state: fromLocation.State) =>
    _.get(state, ['list', 'meta'], {
      total: 0,
      count: 0,
      offset: 0,
      limit: 0,
      page: 0,
    })
);

/**
 * Get Locations Total
 */
export const getLocationsTotal = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'meta', 'total']) || 0
);

/**
 * Get Location list links, to load next bunch of data
 */
export const getLocationsLinks = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'links'])
);

/**
 * Get if Location list is loading
 */
export const isLocationListLoading = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'loading'], false)
);

/**
 * Get if Location list is loading next bunch of data
 */
export const isLocationListLoadingNext = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'loadingNext'], false)
);

/**
 * get if Location list is loaded
 */
export const isLocationListLoaded = createSelector(
  selectLocationState,
  (state: fromLocation.State) => _.get(state, ['list', 'loaded'], false)
);

/**
 * get Location
 */
export const getLocation = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(selectEntities(state), [props.uuid])
  );

/**
 * get Location last error
 */
export const getLocationError = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['error', props.uuid])
  );

/**
 * get if Location is loading
 */
export const isLocationLoading = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['loading', props.uuid], false)
  );

/**
 * get if Location is patching
 */
export const isLocationPatching = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['patching', props.uuid], false)
  );

/**
 * get if Location is deleting
 */
export const isLocationDeleting = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['deleting', props.uuid], false)
  );

/**
 * get if Location is busy (=loading, patching or deleting === true)
 */
export const isLocationBusy = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['loading', props.uuid]) === true ||
    _.get(state, ['patching', props.uuid]) === true ||
    _.get(state, ['deleting', props.uuid]) === true
      ? true
      : false
  );

/**
 * get if Location is ready loaded
 */
export const isLocationLoaded = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['loaded', props.uuid], false)
  );

/**
 * get if Location is watching
 */
export const isLocationWatching = (props: { uuid: string }) =>
  createSelector(selectLocationState, (state: fromLocation.State) =>
    _.get(state, ['watching', props.uuid], false)
  );

/**
 * get selected Location (by route param)
 */
export const getSelectedLocation = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) => {
    return _.get(state, ['list', 'entities', location_uuid]);
  }
);

/**
 * get selected Location (by route param) last error
 */
export const getSelectedLocationError = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['error', location_uuid])
);

/**
 * get if selected Location (by route param) is loading
 */
export const isSelectedLocationLoading = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['loading', location_uuid], false)
);

/**
 * get if selected Location (by route param) is patching
 */
export const isSelectedLocationPatching = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['patching', location_uuid], false)
);

/**
 * get if selected Location (by route param) is deleting
 */
export const isSelectedLocationDeleting = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['deleting', location_uuid], false)
);

/**
 * get if selected Location (by route param) is busy (=loading, patching or deleting === true)
 */
export const isSelectedLocationBusy = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['loading', location_uuid]) === true ||
    _.get(state, ['patching', location_uuid]) === true ||
    _.get(state, ['deleting', location_uuid]) === true
      ? true
      : false
);

/**
 * get if selected Location (by route param) is ready loaded
 */
export const isSelectedLocationLoaded = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['loaded', location_uuid], false)
);

/**
 * get if selected Location (by route param) is watching
 */
export const isSelectedLocationWatching = createSelector(
  selectLocationState,
  selectRouteNestedParam('location_uuid'),
  (state: fromLocation.State, location_uuid) =>
    _.get(state, ['watching', location_uuid], false)
);

/**
 * get location for current Project
 */
export const getCurrentLocation = createSelector(
  getLocations,
  getCurrentProjectLocationUuid,
  (state, current_project_location_uuid) => {
    return _.find(state, { object_uuid: current_project_location_uuid });
  }
);

/**
 * find out if currently selected location has distributed storage
 */
export const locationHasDistributedStorage = createSelector(
  getCurrentLocation,
  (location) => {
    return (
      (location?.features as any)?.has_distributed_storage === undefined ||
      (location?.features as any)?.has_distributed_storage === 'TRUE'
    );
  }
);

export const locationHasDistributedStorageByLocationUuid = (props: {
  uuid: string;
}) =>
  createSelector(getLocations, (state) => {
    const myLocation = _.find(state, { object_uuid: props.uuid });

    if (!myLocation) {
      return false;
    }

    return (
      (myLocation?.features as any)?.has_distributed_storage === undefined ||
      (myLocation?.features as any)?.has_distributed_storage === 'TRUE'
    );
  });

/**
 * find out if currently selected location has rocket storage
 */
export const locationHasRocketStorage = createSelector(
  getCurrentLocation,
  (location) => {
    return (
      (location?.features as any)?.has_rocket_storage === undefined ||
      (location?.features as any)?.has_rocket_storage === 'TRUE'
    );
  }
);

export const locationHasRocketStorageByLocationUuid = (props: {
  uuid: string;
}) =>
  createSelector(getLocations, (state) => {
    const myLocation = _.find(state, { object_uuid: props.uuid });

    if (!myLocation) {
      return false;
    }

    return (
      (myLocation?.features as any)?.has_rocket_storage === undefined ||
      (myLocation?.features as any)?.has_rocket_storage === 'TRUE'
    );
  });

/**
 * get location for current Project if it is s3 enabled, otherwise returns the first location with s3 endpoint set
 */
export const getLocationWithS3Endpoint = createSelector(getLocations, getCurrentProjectLocationUuid, (state, current_project_location_uuid: string) => {

  // TODO: remove this when seeding delivers correct location data for f00f s3
  if (environment.apiUrl.match(/\.gridscale\.dev/) || document?.location?.hostname?.match(/\.gridscale\.dev/)) {
    const f00f = _.find(state, l => l.name === 'f00f' || l.name === 'stage');
    if (f00f) {
      return {
        ...f00f,
        features: {
          ...(f00f.features || {}),
          object_storage_region: f00f.name
        }
      }
    }
  }

  const currentLoc = _.find(state, {
    object_uuid: current_project_location_uuid,
  });
  if (currentLoc?.features?.object_storage_region) {
    return currentLoc;
  }
  const firstWithS3 = _.find(state, (o) => {
    if (o.features?.object_storage_region) {
      return true;
    }
    return false;
  });
  return firstWithS3;
});

/**
 * get location with s3 endpoint set, excluding `excludeLocationUuids`
 */
export const getLocationWithS3EndpointExcludingRegions = (props: {
  excludeRegions: string[];
}) =>
  createSelector(getLocations, (state) => {
    const firstWithS3 = _.find(state, (o) => {
      if (
        o.features?.object_storage_region &&
        props.excludeRegions.indexOf(o.features?.object_storage_region) === -1
      ) {
        return true;
      }
      return false;
    });
    return firstWithS3;
  });

/**
 * Returns true|false if the currentLocation is Virtual
 */
export const isCurrentLocationVirtual = createSelector(
  getCurrentLocation,
  (_currentLocation) => {
    if (_currentLocation) {
      return _currentLocation.public === false;
    }
    return false;
  }
);

/**
 * returns if the location list has `next` in link. Normally we would use meta data to determine, but it is buggy on that location (BUG-3104)
 */
export const hasLocationListNext = createSelector(
  selectLocationState,
  (state: fromLocation.State) =>
    _.get(state, ['list', 'links', 'next']) !== undefined
);

export const hasLocationAccess = (props: { uuid: string }) =>
  createSelector(getLocations, (locations) =>
    locations.some((elem) => elem.object_uuid === props.uuid)
  );
