import { createReducer, on } from '@ngrx/store';
import * as LocationActions from './location.actions';
import { HttpErrorResponse } from '@angular/common/http';

import { Location, RequestOptions, Meta, Links, LocationsGetResponse, LocationWithRelations } from '@gridscale/gsclient-js';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';
import * as _ from 'lodash';

export const locationFeatureKey = 'location';

export const adapter: EntityAdapter<LocationWithRelations> = createEntityAdapter<LocationWithRelations>({
  selectId: a => a.object_uuid!, // define "primary id"
  sortComparer: false
});

export const listAdapter: EntityAdapter<Location> = createEntityAdapter<Location>({
  selectId: (a => a.object_uuid!)!, // define "primary id"
  sortComparer: false
});

export const listAdapterInitialState = listAdapter.getInitialState({
  listConfig: {
    limit: 100,
    sort: '-name'
  },
  loaded: false,
  loading: false,
  loadingNext: false
}) as ListState;

export interface ListState extends EntityState<Location> {
  listConfig: RequestOptions;
  loaded: boolean;
  loading: boolean;
  loadingNext: boolean;
  meta?: Meta;
  links?: Links<LocationsGetResponse>;
  error?: Error | HttpErrorResponse | undefined;
}

export interface State extends EntityState<LocationWithRelations> {
  loading: { [key: string]: boolean };
  loaded: { [key: string]: boolean };

  error?: { [key: string]: Error | HttpErrorResponse | undefined };
  list: ListState;
}
export const initialState: State = adapter.getInitialState({
  loading: {},
  loaded: {},

  list: listAdapterInitialState
});

export const reducer = createReducer(
  initialState,

  on(LocationActions.setListConfig, (state, action) => {
    if (_.isEqual(_.get(state, 'list.listConfig', {}), action.config)) {
      return state;
    }
    return {
    // update list config / empty collection
      ...state,

      list: listAdapter.removeAll({
        ...state.list,
        loaded: false,
        listConfig: {
          // ...state.listConfig,
          ...action.config
        }
      })
    };
  }),

  on(LocationActions.loadLocations, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: true
    }
  })),

  on(LocationActions.loadLocationsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,

    list: listAdapter.setAll(_.toArray(action.result), {
      ...state.list,
      meta: action.meta,
      links: action.links,
      loading: false,
      loaded: true,
      error: undefined
    })
  })),

  on(LocationActions.loadLocationsFailure, (state, action) => ({
    ...state,

    list: listAdapter.removeAll({
      ...state.list,
      loading: false,
      loaded: true,
      error: action.error
    })
  })),

  on(LocationActions.updateLocations, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: true
    }
  })),

  on(LocationActions.updateLocationsSuccess, (state: State, action) => ({
    // set new entities (clears collection)
    ...state,

    list: listAdapter.setAll(_.toArray(action.result), {
      ...state.list,
      meta: action.meta,
      loading: false,
      loaded: true,
      error: undefined
    })
  })),

  on(LocationActions.updateLocationsFailure, (state, action) => ({
    ...state,

    list: listAdapter.removeAll({
      ...state.list,
      loading: false,
      loaded: true,
      error: action.error
    })
  })),

  on(LocationActions.loadLocationsNext, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: _.get(state, ['list', 'links', 'next']) !== undefined ? true : false,
      loadingNext: _.get(state, ['list', 'links', 'next']) !== undefined ? true : false
    }
  })),

  on(LocationActions.loadLocationsNextSuccess, (state: State, action) => ({
    // upsert entities when next page loaded...
    ...state,

    list: listAdapter.upsertMany(_.toArray(action.result), {
      ...state.list,
      meta: action.meta,
      links: action.links,
      loading: false,
      loadingNext: false,
      loaded: true,
      error: undefined
    })
  })),

  on(LocationActions.loadLocationsNextFailure, (state, action) => ({
    ...state,

    list: {
      ...state.list,
      loading: false,
      loadingNext: false,
      loaded: true,
      error: action.error
    }
  })),

  on(LocationActions.loadLocation, (state, action) => ({
    ...state,

    loading: { ...state.loading, [action.uuid]: true },
    loaded: { ...state.loaded, [action.uuid]: false }
  })),

  on(LocationActions.loadLocationSuccess, (state, action) => {
    return adapter.setOne(action.data, {
      ...state,
      error: { ...state.error, [action.uuid]: undefined },
      loading: { ...state.loading, [action.uuid]: false },
      loaded: { ...state.loaded, [action.uuid]: true }
    });
  }),

  on(LocationActions.loadLocationFailure, (state, action) => ({
    ...state,

    error: { ...state.error, [action.uuid]: action.error },
    loading: { ...state.loading, [action.uuid]: false },
    loaded: { ...state.loaded, [action.uuid]: true }
  })),

  on(LocationActions.loadSelectedLocation, (state, action) => ({
    ...state
  })),

  on(LocationActions.loadSelectedLocationStart, (state, action) => ({
    ...state,

    loading: { ...state.loading, [action.uuid]: true },
    loaded: { ...state.loaded, [action.uuid]: false }
  })),

  on(LocationActions.loadSelectedLocationSuccess, (state, action) => {
    return adapter.setOne(action.data, {
      ...state,
      error: { ...state.error, [action.uuid]: undefined },
      loading: { ...state.loading, [action.uuid]: false },
      loaded: { ...state.loaded, [action.uuid]: true }
    });
  }),

  on(LocationActions.loadSelectedLocationFailure, (state, action) => ({
    ...state,

    error: { ...state.error, [action.uuid]: action.error },
    loading: { ...state.loading, [action.uuid]: false },
    loaded: { ...state.loaded, [action.uuid]: true }
  }))

  /******* CUSTOM REDUCERS FROM HERE ******/
);
