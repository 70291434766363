import { createAction, props } from '@ngrx/store';

import { RequestPollResult, RequestOptions, Meta, Links, LocationIndex, LocationsGetResponse, Location, LocationWithRelations } from '@gridscale/gsclient-js';

export const actionPrefix = '[location]';

/********************************
 *          LIST ACTIONS        *
 ********************************/

export const setListConfig = createAction(actionPrefix + ' Set Location List Config', props<{ config: RequestOptions }>());

export const loadLocations = createAction(actionPrefix + ' Load Location List');

export const loadLocationsSuccess = createAction(actionPrefix + ' Load Location List Success', props<{ result: LocationIndex; meta?: Meta; links?: Links<LocationsGetResponse> }>());

export const loadLocationsFailure = createAction(actionPrefix + ' Load Location List Failure', props<{ error: Error }>());

export const updateLocations = createAction(actionPrefix + ' Update Location List');

export const updateLocationsSuccess = createAction(actionPrefix + ' Update Location List Success', props<{ result: LocationIndex; meta?: Meta }>());

export const updateLocationsFailure = createAction(actionPrefix + ' Update Location List Failure', props<{ error: Error }>());

export const loadLocationsNext = createAction(actionPrefix + ' Load Location List Next');

export const loadLocationsNextSuccess = createAction(
  actionPrefix + ' Load Location List Next Success',
  props<{ result: LocationIndex; meta?: Meta; links?: Links<LocationsGetResponse> }>()
);

export const loadLocationsNextFailure = createAction(actionPrefix + ' Load Location List Next Failed', props<{ error: Error }>());

/********************************
 *          READ ACTIONS        *
 ********************************/

export const loadLocation = createAction(actionPrefix + ' Load Location', props<{ uuid: string }>());

export const loadLocationSuccess = createAction(actionPrefix + ' Load Location Success', props<{ data: LocationWithRelations; uuid: string }>());

export const loadLocationFailure = createAction(actionPrefix + ' Load Location Failure', props<{ error: Error; uuid: string }>());

export const loadSelectedLocation = createAction(actionPrefix + ' Load Selected Location');

export const loadSelectedLocationStart = createAction(actionPrefix + ' Load Selected Location Start', props<{ uuid: string }>());

export const loadSelectedLocationSuccess = createAction(actionPrefix + ' Load Selected Location Success', props<{ data: Location; uuid: string }>());

export const loadSelectedLocationFailure = createAction(actionPrefix + ' Load Selected Location Failure', props<{ error: Error; uuid: string }>());

/********************************
 *      INTERNAL ACTIONS        *
 ********************************/

/******* CUSTOM ACTIONS FROM HERE ******/

export const loadCurrentLocation = createAction(actionPrefix + ' Load current Location');
